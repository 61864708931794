.mainBar {
    margin-top: 2rem;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40rem;
    box-shadow: 14;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white !important;
}

.placeholderOrderlist {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
}

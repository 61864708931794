.ticketWrapper {
    padding: 1rem 1rem 0rem 0rem;
    margin: 0.5rem 0 0.5rem 0;
    border-top: 1px solid #dce7d2;
}

.ticketWrapperExtended {
    padding: 1rem 1rem 1rem 1rem;
    margin: 0.5rem 0 0rem 0;
    border: 1px solid #dce7d2;
    border-left: 0.5rem solid var(--primary-color);
    cursor: pointer;
}
.ticketWrapperExtendedMarked {
    background-color: #f5f5f5;
}

.description {
    max-height: 2.5rem;
    overflow: hidden;
    margin: 0.5rem 0 0.5rem 0;
}

.ticketButton {
    margin: 1rem 0 0 0 !important;
}

.previewList {
    min-height: calc(100vh - 4rem);
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
}

.activeCase {
    border-left: 1px lightgrey solid;
    margin-left: 0.5rem;
    overflow-y: auto;
    min-height: calc(100vh - 4rem);
    max-height: calc(100vh - 4rem);
}

.activeCase img {
    margin: 10px; /* Adjust the margin value as needed */
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 60rem;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 14;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white !important;
}

.placeholder {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
}

.ticketHeader {
    background-color: var(--primary-color);
    padding: 0.5rem;
    margin-bottom: 1rem;
}

body {
    margin: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --primary-color: #dfe6f5;
    --header-color: #f5f5f5;
    --old: #e9f0e3;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.pageHeader {
    padding: 0.8rem 1rem 1rem 1rem;
}

.paddingPages {
    padding: 0 0rem 0 0rem;
}

.pageHeaderWrapper {
    height: 3.5rem;
    position: relative;
    background-color: var(--header-color);
    border-bottom: 1px lightgrey solid;
}

.iconPlaceholder {
    width: 100%;
    padding-top: 3rem;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table thead {
    background-color: var(--primary-color);
    padding: 0 !important;
    opacity: 0.6;
    height: 3rem;
}

.cell .table {
    padding-right: 10px;
}

.table td {
    height: 2.5rem;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: solid 1px rgb(229, 229, 229);
}

.table th {
    text-align: left;
    padding: 0.5rem 1rem 0.5rem 1rem;
    font-weight: 300;
    color: black;
}

.table thead tr:hover {
    background-color: unset;
}

.table a {
    text-decoration: none;
}

.tableCenter {
    text-align: center !important;
}

.tableRight {
    text-align: right !important;
}

.separator {
    margin: 1rem 0 0 0;
    border-bottom: 1px rgb(229, 229, 229) solid;
}

.layout_content {
    overflow-y: auto;
    max-width: calc(100% - 12rem);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    box-shadow: 14;
    padding: 2rem;
    border-radius: 1rem;
    background-color: white !important;
}

.tableWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow-y: auto;
}

.placeholder {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
}
